import ClickAwayListener from 'react-click-away-listener'
import { DayMouseEventHandler, DayPicker } from 'react-day-picker'

import { useState } from "react"

interface Props {
  value?: Date,
  available?: any,
  onChange?: Function
}

export default function DatePicker({ value, available, onChange }: Props) {
  const [opened, setOpened] = useState(false)

  const handleDayClick: DayMouseEventHandler = (day, { available }) => {
    if (available) {
      onChange?.(day)
    }
  }

  return (
    <ClickAwayListener onClickAway={() => setOpened(false)}>
      <div className="n-relative">
        <div className={`n-relative n-cursor-pointer input ${opened ? 'focus' : ''}`}
          onClick={() => setOpened(!opened)}>
          <span>{value?.toLocaleDateString('sv-SV')}</span>
          <svg className={`n-absolute n-right-3 n-top-1/2 -n-translate-y-1/2 ${opened ? 'n-rotate-180' : ''}`} width="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 11L3 5.99999L3.7 5.29999L8 9.59999L12.3 5.29999L13 5.99999L8 11Z" fill="currentColor"/></svg>
        </div>
        {opened && <DayPicker className="n-absolute n-mt-2 n-z-1"
          modifiers={{ available }}
          modifiersClassNames={{ available: "rdp-day_available" }}
          mode="single"
          selected={value}
          showOutsideDays
          today={value}
          onDayClick={handleDayClick} />
        }
      </div>
    </ClickAwayListener>
  )
}