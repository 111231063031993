interface Props {
  item: TTimeslot,
  selected: boolean,
  onSelect?: Function
}

export default function Timeslot({ item, selected, onSelect }: Props) {
  const disabled = item.state === 'CLOSED' || item.state === 'SOLD_OUT'
  const beautyValue = `${item.timeslotStart.substring(0, 5)} – ${item.timeslotEnd.substring(0, 5)}`
  const states:any = {
    CLOSED: 'N/A', 
    SOLD_OUT: 'N/A', 
    FEW_LEFT: 'Few left', 
    OPEN: ''
  }

  function handleSelect() {
    if (!disabled) {
      onSelect?.(item)
    }
  }

  return (
    <li className={'tp-item' +
        ` ${selected ? 'selected' : ''}` + 
        ` ${item.state === 'CLOSED' || item.state === 'SOLD_OUT' ? 'disabled' : ''}`
      }
      onClick={handleSelect}>
      <span className="tp-value">{beautyValue}</span>
      <span className="tp-label">{states[item.state]}</span>
      <svg width="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.5 12L2 7.5L2.707 6.793L6.5 10.5855L13.293 3.793L14 4.5L6.5 12Z" fill="currentColor"/></svg>
    </li>
  )
}