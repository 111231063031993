import { FormEvent, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useMutation } from "@apollo/client"
import LOGIN from 'graphql/auth-login.gql'

export default function Login() {
  const navigate = useNavigate()

  const [input, setInput] = useState({
    email: '',
    password: ''
  })
  // const [errors] = useState([])
  const [sent, setSent] = useState(false)

  const [Login] = useMutation(LOGIN)

  useEffect(() => {
    if (localStorage.getItem(process.env.REACT_APP_TOKEN as string))
      navigate('/check')
  })

  function handleInput(e: any) {
    setInput({ ...input, [e.target.name]: e.target.value })
  }

  function handleSubmit(e: FormEvent) {
    setSent(true)
    e.preventDefault()
    Login({ variables: { input } })
      .then(({ data }) => {
        if (data.signIn.successful) {
          const { token } = data.signIn.result
          localStorage.setItem(process.env.REACT_APP_TOKEN as string, token)
          navigate('/check')
        } else {
          alert(data.signIn.messages?.[0]?.message)
          setSent(false)
        }
      })
      .catch((error) => {
        alert(error)
        // setErrors(getGQLErrorMessages(graphQLErrors))
        // this.errors = getGQLErrorMessages(graphQLErrors)
        setSent(false)
      })
  }

  return (
    <form action="" onSubmit={handleSubmit}>
      <div className="n-flex n-items-start n-justify-between">
        <div className="n-leading-4">
          <h2>Ticket validation</h2>
        </div>
        <div className="n-font-medium n-text-sm">NAOM</div>
      </div>
      <div className="n-mt-12">
        <label htmlFor="">E-mail</label>
        <input id="email"
          name="email"
          className="input"
          type="email"
          value={input.email}
          required
          onChange={handleInput} />
      </div>
      <div className="n-mt-2">
        <label htmlFor="">Password</label>
        <input id="password"
          name="password"
          className="input n-mt-4"
          type="password"
          value={input.password}
          required
          onChange={handleInput} />
      </div>
      <button className="btn btn--primary n-w-full n-mt-5"
        type="submit"
        disabled={sent}>Login</button>
    </form>
  )
}