import Tabs from 'components/Tabs'
import Product from 'components/Product'
import Guests from 'components/Guests'

import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import { setProductData, setStep } from 'app/store-slice'
import { useQuery } from '@apollo/client'
import GET_QUERY from 'graphql/product-get.gql'


export default function Item() {
  const store = useAppSelector((s) => s.main)
  const dispatch = useAppDispatch()

  const { productSlug, productKey } = useParams()
  
  const { data } = useQuery(GET_QUERY, { 
    variables: { slug: productSlug, secretKey: productKey } 
  })

  useEffect(() => {
    if (data?.product) {
      document.title = data.product.seoTitle || process.env.REACT_APP_TITLE
      document.querySelector('meta[name="keywords"]')
        ?.setAttribute("content", data.product.seoKeywords || process.env.REACT_APP_KEYWORDS)
      document.querySelector('meta[name="description"]')
        ?.setAttribute("content", data.product.seoDescription || process.env.REACT_APP_DESCRIPTION)
        
      dispatch(setProductData({ ...data.product, secretKey: productKey }))
    } else if (data?.product === null) {
      dispatch(setProductData({ id: null }))
    }
  }, [data])
  
  return (
    <>
      <Tabs active={store.step} />

      {store.step === 'Tickets' &&
        <Product nextStep={() => dispatch(setStep('Guests'))} />
      }
      {store.step === 'Guests' && 
        <Guests prevStep={() => dispatch(setStep('Tickets'))} />
      }
    </>
  )
}
