// import { IMaskInput } from 'react-imask'

import { ChangeEvent, FormEvent, MouseEventHandler, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { setGuestData, setOrderData } from 'app/store-slice'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import UPDATE_ORDER from 'graphql/order-update.gql'

interface Props {
  prevStep: Function
}

export default function Guests({ prevStep }: Props) {
  const store = useAppSelector((s) => s.main)
  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const [error, setError] = useState('')

  const [Update] = useMutation(UPDATE_ORDER)

  const [showAdditional, setShowAdditional] = useState(false)
  const product:TProduct = store.product

  const isFilled = 
    (store.guests[0].firstName.length 
      && store.guests[0].lastName.length
      && store.guests[0].email.length)

  useEffect(() => {
    window?.addGtmOrderEvent(store.order, 'order_start_checkout')
  }, [])

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const [key, index] = e.target.id.split('-')
    let val = e.target.value

    if (key === 'code' && !val)
      val = '+'

    dispatch(setGuestData({ index, key, value: val }))
  }
  function proceed(e: FormEvent) {
    e.preventDefault()

    const guests = store.guests
      .filter(({ firstName, lastName, email, phone }: TGuest) => (
        firstName || lastName || email || phone
      ))
      .map((guest: TGuest, i: number) => ({
        firstName: guest.firstName,
        lastName: guest.lastName,
        email: guest.email,
        phone: (guest.phone ? guest.code + guest.phone : ''),
        unitId: +(guest.unitId ?? 0),
        productId: +product.id
      }))

    Update({ variables: { 
      id: store.order.id,
      secretKey: store.order.secretKey,
      input: { guests } 
    } })
      .then(({ data }) => {
        if (data.updateOrder.successful) {
          dispatch(setOrderData(data.updateOrder.result))
          
          // REMOVE LATER
          const link = (store.product.visibility === 'PRIVATE'
            ? `/${store.product.slug}/payment/${store.order.id}/${store.order.secretKey}`
            : `/payment/${store.order.id}/${store.order.secretKey}`)
          // --------
          navigate(link)
        } else {
          setError(data.updateOrder.messages?.[0].message)
        }
      })
      .catch((error) => console.log(error))
  }
  
  return (
    <form onSubmit={proceed} method="POST" className="guests n-mt-6">
      <div>
        <label htmlFor="">Your first name *</label>
        <input id="firstName-0"
          name="firstname"
          className="input" 
          type="text" 
          value={store.guests[0].firstName}
          required
          onChange={handleChange} />
      </div>
      <div className="n-mt-3">
        <label htmlFor="">Your last name *</label>
        <input id="lastName-0"
          name="lastname"
          className="input" 
          type="text" 
          value={store.guests[0].lastName}
          required
          onChange={handleChange} />
      </div>
      <div className="n-mt-3">
        <label htmlFor="">Your e-mail *</label>
        <input id="email-0"
          name="email"
          className="input"
          type="email"
          value={store.guests[0].email}
          required
          onChange={handleChange} />
      </div>
      <div className="n-mt-3 flex gap-4">
        <label htmlFor="">Your phone</label>
        <div className="n-flex n-gap-4 n-mt-1">
          <div className="n-w-24">
            <input id="code-0"
              type="tel"
              name="code"
              className="input"
              value={store.guests[0].code}
              placeholder="+46"
              onChange={handleChange} />
          </div>
          <input id="phone-0"
            name="phone"
            type="tel"
            className="input"
            value={store.guests[0].phone}
            placeholder="Include to receive ticket as SMS"
            onChange={handleChange} />
        </div>
      </div>

      {store.guests.length > 1 && 
        <button type="button" className="guests-more n-mt-10"
          onClick={() => setShowAdditional(!showAdditional)}>
          <span>Add other guests (optional)</span>
          <svg className={showAdditional ? 'n-rotate-180' : ''} width="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 11L3 5.99999L3.7 5.29999L8 9.59999L12.3 5.29999L13 5.99999L8 11Z" fill="currentColor"/></svg>
        </button>
      }

      {showAdditional && store.guests.toSpliced(0, 1).map((guest: any, i: number) => (
        <div key={i} className="">
          <div className="n-mt-5 n-mb-4">
            Guest {i+2} {store.product.unitGroup.units.length > 1 ? `(${guest.unitName})` : ''}
          </div>
          <div>
            <label htmlFor="">First name</label>
            <input id={`firstName-${i+1}-${guest.unitId}`}
              className="input" 
              type="text" 
              value={guest.firstName}
              onChange={handleChange} />
          </div>
          <div className="n-mt-3">
            <label htmlFor="">Last name</label>
            <input id={`lastName-${i+1}`}
              className="input" 
              type="text" 
              value={guest.lastName}
              onChange={handleChange} />
          </div>
          <div className="n-mt-3">
            <label htmlFor="">E-mail</label>
            <input id={`email-${i+1}`}
              className="input"
              value={guest.email}
              onChange={handleChange} />
          </div>
          <div className="n-mt-3">
            <label htmlFor="">Phone</label>
            <div className="n-flex n-gap-4 n-mt-1">
              <div className="n-w-24">
                <input id={`code-${i+1}`}
                  type="tel"
                  className="input"
                  placeholder="+46"
                  value={guest.code}
                  onChange={handleChange} />
              </div>
              <input id={`phone-${i+1}`}
                type="tel"
                className="input"
                placeholder="Include to receive ticket as SMS"
                value={guest.phone}
                onChange={handleChange} />
            </div>
          </div>
        </div>
      ))}

      {!!error.length && (
        <div className="n-text-red-500 n-mt-6">{error}</div>
      )}

      <div className="n-flex n-items-center n-justify-between n-gap-6 n-mt-6">
        <div className="n-w-1/2">
          <button type="button" className="btn btn--link"
            onClick={prevStep as MouseEventHandler}>
            <svg width="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 8L10 3L10.7 3.7L6.4 8L10.7 12.3L10 13L5 8Z" fill="currentColor"/></svg>
            <span>Back</span>
          </button>
        </div>
        <button type="submit" 
          className="btn btn--primary n-w-1/2"
          disabled={!isFilled}>Proceed to payment</button>
      </div>
    </form>
  )
}