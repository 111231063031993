import { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import html2canvas from "html2canvas"
import { useReactToPrint } from "react-to-print"
import generatePDF from "react-to-pdf"
import { useQuery } from '@apollo/client'
import GET_TICKET from 'graphql/ticket-get.gql'

export default function Ticket() {
  const { ticketCode } = useParams()

  const { data } = useQuery(GET_TICKET, { variables: { code: ticketCode } })
  const [ticket, setTicket] = useState<any>({})
  const guestsText = ticket?.guestQuantity + (ticket?.guestQuantity > 1 ? ' guests' : ' guest')

  const ticketRef = useRef<any>(null)
  const ticketCodeRef = useRef<any>(null)
  const handlePrint = useReactToPrint({
    content: () => ticketRef.current
  })
  const search = new URLSearchParams(window.location.search)

  useEffect(() => {
    if (data?.ticket?.qrCodeDataUri) {
      setTicket(data.ticket)
    } else if (data?.ticket === null) {
      setTicket({ id: null })
    }
  }, [data])

  function handleLoad() {
    ticketRef.current.style.height = (window.innerHeight - 48) + 'px'
  
    if (search.get('pdf') !== null) {
      generatePDF(() => ticketRef.current.parentNode, {
        filename: `${ticket.product.name}.pdf`,
        overrides: {
          pdf: {
            format: 'dl',
          }
        }
      })
    }

    if (search.get('print') !== null) {
      html2canvas(ticketRef.current, { allowTaint: true })
        .then(function(canvas) {
          ticketRef.current.replaceChildren(canvas)
          handlePrint()
        })
    }
  }

  return (ticket?.id === null || ticket?.state === 'CANCELLED') ? (
    <div className="">
      Ticket unavailable or not found.
    </div>
  ) : ticket?.id ? (
    <div ref={ticketRef} className="sm:!n-h-auto">
      <div className="n-flex n-items-start n-justify-between n-absolute n-w-full n-left-0 n-px-6">
        <div className="n-leading-4">
          <h2>{ticket.product.name}</h2>
          <div className="n-whitespace-pre-line">
            {ticket.visitTime ? (
              `${ticket.visitTime.visitDate} ${ticket.visitTime.visitStart.replace(':00', '')} – ${ticket.visitTime.visitEnd.replace(':00', '')}`
            ) : (ticket.product.description) }
            <br/>
            {guestsText}
          </div>
        </div>
        <div className="n-font-medium n-text-sm">NAOM</div>
      </div>

      <div ref={ticketCodeRef}
        className="n-flex n-items-center n-w-44 n-h-full n-py-44 n-mx-auto">
        <img onLoad={handleLoad} src={ticket?.qrCodeDataUri} />
      </div>
    </div>
  ) : (
    <div className="">
      Loading...
    </div>
  )
}