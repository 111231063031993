import Tabs from 'components/Tabs'
import Adyen from 'components/Adyen'

import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { setOrderData, setStep } from 'app/store-slice'
import { useNavigate, useParams } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import GET_QUERY from 'graphql/order-get.gql'
import PAY_ORDER from 'graphql/order-pay.gql'

export default function Payment() {
  const store = useAppSelector((s) => s.main)
  const dispatch = useAppDispatch()

  const { orderId, orderKey } = useParams()
  const navigate = useNavigate()

  const [getOrder, { data }] = useLazyQuery(GET_QUERY)
  const [PayOrder] = useMutation(PAY_ORDER)

  const totalPrice = store.order?.total / 100
  const isFree = store.order?.total === 0
  
  const [showHint, setShowHint] = useState(false)
  const [storageConsent, setStorageConsent] = useState(true)
  const [accept, setAccept] = useState(false)

  useEffect(() => {
    if (data?.order !== undefined) {
      dispatch(setOrderData(data.order))
      if (data.order?.state === 'COMPLETED' || data.order?.state === 'APPROVAL_REQUIRED') {
        navigate(`/success/${orderId}/${orderKey}`)
      }
    } else if (!store.order.id) {
      getOrder({ variables: { orderId, secretKey: orderKey } })
    }
  }, [data])

  useEffect(() => {
    window?.addGtmOrderEvent(store.order, 'order_start_payment')
  }, [])

  function proceed() {
    if (!accept) {
      return
    }

    if (isFree) {
      PayOrder({ variables: { 
        id: store.order.id,
        secretKey: store.order.secretKey,
        input: { 
          provider: "NONE",
          storageConsent
        }
      } })
        .then(({ data }) => {
          if (data.payOrder.successful) {
            // reset steps
            dispatch(setStep('Tickets'))

            // REMOVE LATER
            const link = (store.product.visibility === 'PRIVATE'
              ? `/${store.product.slug}/success/${store.order.id}/${store.order.secretKey}`
              : `/success/${store.order.id}/${store.order.secretKey}`)
            // --------
            navigate(link)
          }
        })
        .catch((error) => console.log(error))
    } else {
      const btn = (document.querySelector('#n-adyen-container .adyen-checkout__payment-method--selected .adyen-checkout__button--pay') as HTMLButtonElement)
      if (btn) {
        window.parent.postMessage('set-opaque-background', '*')
        btn.click()
      }
    }
  }
  function back() {
    const link = store.product.visibility === 'PRIVATE'
      ? `/${store.product.slug}/${store.product.secretKey}`
      : `/${store.product.slug}`
    navigate(link)
  }

  return (
    <div className="payment">
      <Tabs active="Payment" />
      
      {!store.order?.id ? (<div className="n-mt-6">Loading...</div>) : (
        <>
          {isFree ? (
            <div className="n-leading-4 n-py-6 n-border-b n-border-accent-500">
              <h2>Free admission</h2>
              <div className="n-whitespace-pre-line">No payment required</div>
            </div>
          ) : (
            <div className="n-mt-6">
              <Adyen storageConsent={storageConsent} />
            </div>
          )}
          <div className="n-mt-5 n-flex n-items-center n-gap-2">
            <input id="remember"
              name="remember"
              type="checkbox" 
              checked={storageConsent}
              onChange={(e: any) => setStorageConsent(e.target.checked)} />
            <label htmlFor="remember">Remember my details</label>

            <div className={`payment-hint ${showHint ? 'opened' : ''}`}>
              <button className="payment-hint__button"
                onClick={() => setShowHint(!showHint)}>
                <svg className="opened" width="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7 0.875C3.5875 0.875 0.875 3.5875 0.875 7C0.875 10.4125 3.5875 13.125 7 13.125C10.4125 13.125 13.125 10.4125 13.125 7C13.125 3.5875 10.4125 0.875 7 0.875ZM7 10.9375C6.65 10.9375 6.3 10.675 6.3 10.2375C6.3 9.8 6.65 9.625 7 9.625C7.35 9.625 7.7 9.8875 7.7 10.325C7.7 10.7625 7.35 10.9375 7 10.9375ZM7.525 7.525V8.575H6.475V6.5625H7.4375C8.05 6.5625 8.4875 6.0375 8.4 5.425C8.4 4.9 7.9625 4.4625 7.4375 4.4625H6.825C6.2125 4.4625 5.775 4.9 5.775 5.5125V5.775H4.725V5.5125C4.725 4.375 5.6875 3.5 6.825 3.5H7.4375C8.575 3.5 9.45 4.4625 9.3625 5.6C9.3625 6.65 8.575 7.525 7.525 7.525Z" fill="black"/></svg>
                <svg className="closed" width="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M0 7C0 3.1 3.1 0 7 0C10.9 0 14 3.1 14 7C14 10.9 10.9 14 7 14C3.1 14 0 10.9 0 7ZM7.79918 10.7997C7.79918 11.2415 7.44101 11.5997 6.99918 11.5997C6.55735 11.5997 6.19918 11.2415 6.19918 10.7997C6.19918 10.3579 6.55735 9.99969 6.99918 9.99969C7.44101 9.99969 7.79918 10.3579 7.79918 10.7997ZM7.49997 2.99969H6.79997C5.49997 2.99969 4.49997 3.99969 4.49997 5.19969V5.39969H5.49997V5.19969C5.49997 4.59969 6.09997 3.99969 6.79997 3.99969H7.59997C8.29997 3.99969 8.79997 4.59969 8.79997 5.19969C8.79997 5.7997 8.19997 6.49969 7.49997 6.49969H6.49997V8.69969H7.49997V7.49969C8.69997 7.49969 9.69997 6.49969 9.69997 5.2997C9.69997 4.09969 8.69997 2.99969 7.49997 2.99969ZM0.999969 6.99976C0.999969 10.2998 3.69997 12.9998 6.99997 12.9998C10.3 12.9998 13 10.2998 13 6.99976C13 3.69976 10.3 0.999756 6.99997 0.999756C3.69997 0.999756 0.999969 3.69976 0.999969 6.99976Z" fill="#171717"/></svg>
              </button>
              <div className="payment-hint__text">
                <div>Your details will be saved in our database for newsletters, special offers and later, if you so wish, a membership. Your details will be safe with us, you can read about our privacy policy <a href="/privacy-policy" target="_blank" className="n-underline">here</a>.</div>
                <button onClick={() => setShowHint(false)}>
                  <svg width="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12 4.7L11.3 4L8 7.3L4.7 4L4 4.7L7.3 8L4 11.3L4.7 12L8 8.7L11.3 12L12 11.3L8.7 8L12 4.7Z" fill="black"/></svg>
                </button>
              </div>
            </div>
          </div>

          <div className="n-mt-4 n-flex n-items-center n-gap-2">
            <input id="accept"
              type="checkbox" 
              checked={accept}
              onChange={(e: any) => setAccept(e.target.checked)} />
            <label htmlFor="accept">
              I Accept the <a href="/terms-and-conditions" target="_blank" className="n-underline" rel="noreferrer">terms & conditions</a>
            </label>
          </div>

          <div className="n-flex n-items-center n-justify-between n-gap-6 n-border-t n-border-accent-500 n-mt-6 n-pt-6">
            <div className="n-w-1/2">
              {!!store.product.id && 
                <button className="btn btn--link"
                  onClick={back}>
                  <svg width="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 8L10 3L10.7 3.7L6.4 8L10.7 12.3L10 13L5 8Z" fill="currentColor"/></svg>
                  <span>Back</span>
                </button>
              }
            </div>
            <button className="btn btn--primary n-w-1/2"
              disabled={!accept}
              onClick={proceed}>{isFree ? 'Book' : `Pay ${totalPrice} kr`}</button>
          </div>
        </>
      )}

    </div>
  )
}