import Tabs from "components/Tabs"

import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { resetData } from "app/store-slice"
import { useParams, NavLink, useNavigate } from "react-router-dom"
import { useLazyQuery } from "@apollo/client"
import GET_QUERY from 'graphql/order-get.gql'

export default function Confirmation() {  
  const store = useAppSelector((s) => s.main)
  const dispatch = useAppDispatch()

  const { orderId, orderKey } = useParams()
  const navigate = useNavigate()

  const [getOrder, { data }] = useLazyQuery(GET_QUERY)

  const order = data?.order
  const loading = !order?.id 
    || (order?.state !== 'COMPLETED' && order?.state !== 'APPROVAL_REQUIRED')

  const product = order?.lineItems[0].product
  const totalGuests = order?.lineItems
    .reduce((result: number, { quantity }: TLineItem) => result += quantity, 0)
  const guestsText = totalGuests + (totalGuests > 1 ? ' guests' : ' guest')

  useEffect(() => {
    setTimeout(() => getOrder({ variables: { orderId, secretKey: orderKey } }), 2000)
  }, [])

  useEffect(() => {
    if (data?.order && store.order.id)
      window.addGtmOrderEvent(data.order, 'order_confirmed')
  }, [data])

  function back() {
    dispatch(resetData())
    window.parent.postMessage('n-ticket-app-close', '*')
  
    if (store.product.visibility === 'PRIVATE') {
      navigate(`/${store.product.slug}/${store.product.secretKey}`)
    } else {
      navigate(`/${store.product.slug}`)
    }

  }

  return (
    <div className="tickets">
      <Tabs active="Confirmation" />

      {loading ? (<div className="n-mt-6">Loading...</div>) : (
        <>
          <div className="n-leading-4 n-my-6">
            <h2>{product.name}</h2>
            <div className="n-whitespace-pre-line">
              {order.lineItems[0].visitTime ? (
                `${order.lineItems[0].visitTime.visitDate} ${order.lineItems[0].visitTime.visitStart.replace(':00', '')} – ${order.lineItems[0].visitTime.visitEnd.replace(':00', '')}`
              ) : (product.description) }
              <br/>
              {guestsText}
            </div>
          </div>

          <table className="table confirmation-table n-w-full">
            <tbody>
              <tr>
                <td></td>
                <td>Price</td>
                <td>Guests</td>
                <td>Sum</td>
              </tr>
              {order.lineItems.map((item: any, index: number) => (
                <tr key={item.id}>
                  <td>{item.unit.name}</td>
                  <td>{item.price / 100} kr</td>
                  <td>{item.quantity}</td>
                  <td>{item.total / 100} kr</td>
                </tr>
              ))}
              <tr>
                <td colSpan={3}>Total price incl. VAT</td>
                <td>{order.total / 100} kr</td>
              </tr>
            </tbody>
          </table>

          <div className="n-mt-6">
            {order?.state === 'COMPLETED' ? (
              <>
                <NavLink to={`/ticket/${order.tickets[0].code}`} 
                  target="_blank"
                  className="btn btn--primary n-w-full">
                  View ticket
                </NavLink>
                <NavLink to={`/ticket/${order.tickets[0].code}?print`} 
                  target="_blank"
                  className="btn btn--primary n-w-full n-mt-6">
                  Print ticket
                </NavLink>
                <NavLink to={`/ticket/${order.tickets[0].code}?pdf`} 
                  target="_blank"
                  className="btn btn--primary n-w-full n-mt-6">
                  Save ticket as PDF
                </NavLink>
                {/* <button className="btn btn--primary n-w-full n-mt-6">
                  Save tickets to wallet
                </button> */}
                <div className="n-mt-6">
                  The tickets will also be sent by e-mail and SMS (if requested)
                </div>
              </>
            ) : (
              <>
                <div className="">
                  The tickets will be e-mailed to you once your booking has been confirmed
                </div>
              </>
            )}
            {!!store.product.slug && (
              <button onClick={back}
                className="btn btn--outline n-w-full n-mt-6">
                OK and close
              </button>
            )}
          </div>
        </>
      )}
    </div>
  )
}