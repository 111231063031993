import './FsagTemplate.scss'

export default function FsagTemplate({ texts, children }: any) {
  document.querySelector('html')!.style.background = 'black'
  document.addEventListener('n-ticket-app-close', togglePopup)

  function closePopup(e: any) {
    if (e.target.classList.contains('n-private__popup')) {
      togglePopup()
    }
  }
  function togglePopup() {
    const popup = document.querySelector('.n-private__popup') as HTMLElement
    popup!.style.height = window.innerHeight + 'px'
    popup?.classList.toggle('opened')
  }

  return (
    <div className="n-fsag">
      <img className="n-fsag__logo" src="/naom-event/logo.png" alt="" />
      <img className="n-fsag__image" src="/fsag-event/image.png" alt="" />
    
      <div className="n-fsag__inner">
        <h1 className="n-fsag__title">
          Exclusive Invitation for FSAG Members
        </h1>
        <h2 className="n-fsag__subtitle">
          Welcome to a preview/<br/>pre-opening, behind-<br/>the-scenes tour of <br/>the Museum by NAOM
        </h2>
        <div className="n-fsag__date">
          <div>When: 2024.06.14</div>
          <div>Time: 18:00 – 20.00</div>
          <div>Venue: NAOM Stockholm</div>
        </div>
        <div className="n-fsag__text">
          Sergelgatan 8, 111 57, Stockholm <br/>RSVP by 12 June 2024
        </div>

        <button className="n-fsag__btn" onClick={togglePopup}>
          Get your ticket
        </button>
        
        <svg className="n-fsag__logo2" height="24" viewBox="0 0 132 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M124.86 0.956055H131.013V23.1099H125.018V11.2372L118.139 23.1099H112.018L105.139 11.2372V23.1099H99.1758V0.956055H105.297L115.079 17.8261L124.86 0.956055Z" fill="white"/>
          <path d="M78.5906 0C87.1731 0 94.1464 5.37931 94.1464 12C94.1464 18.6525 87.1731 24 78.5906 24C70.0081 24 63.0664 18.6525 63.0664 12C63.0664 5.37931 70.0081 0 78.5906 0ZM78.5906 19.2573C83.7654 19.2573 87.9935 16.0106 87.9935 12C87.9935 8.02122 83.7654 4.77454 78.5906 4.77454C73.4159 4.77454 69.2193 8.02122 69.2193 12C69.2193 16.0106 73.4159 19.2573 78.5906 19.2573Z" fill="white"/>
          <path d="M49.1715 0.922607L62.0453 23.0764H55.8924L53.3366 18.6839H38.8852L36.3293 23.0764H30.208L43.0502 0.922607H49.1715ZM41.5041 14.164H50.7176L46.1109 6.20643L41.5041 14.164Z" fill="white"/>
          <path d="M19.4684 0.955078H25.4635V23.1089H19.3106L5.96357 8.68983V23.1089H0V0.955078H6.27911L19.4684 15.215V0.955078Z" fill="white"/>
        </svg>
      </div>

      <div onClick={closePopup} className="n-private__popup">
        <div className="n-private__popup-inner">
          <div className="n-main-container">
            {children}
          </div>
          <svg onClick={togglePopup} className="n-private__popup__close" fill="currentColor" width="16" viewBox="0 0 32 32" aria-hidden="true">
            <path d="M24 9.4L22.6 8 16 14.6 9.4 8 8 9.4 14.6 16 8 22.6 9.4 24 16 17.4 22.6 24 24 22.6 17.4 16 24 9.4z"></path>
          </svg>
        </div>
      </div>
    </div>
  )
}