import './EliTemplate.scss'

export default function EliTemplate({ children }: any) {
  document.addEventListener('n-ticket-app-close', togglePopup)

  function closePopup(e: any) {
    if (e.target.classList.contains('n-private__popup')) {
      togglePopup()
    }
  }
  function togglePopup() {
    const popup = document.querySelector('.n-private__popup') as HTMLElement
    popup!.style.height = window.innerHeight + 'px'
    popup?.classList.toggle('opened')
  }

  return (
    <div className="n-private">
      <picture className="n-private-bg">
        <source media="(orientation: portrait) and (max-width: 639px)"
          srcSet="/private-event/bg-mobile.png" />
        <img src="/private-event/bg.png" alt="" />
      </picture>

      <div className="n-private-inner">
        <img className="n-private__eli" src="/private-event/eli-logo.png" alt="" />
        <div className="n-private__when">
          When: 2024.06.07 // Time: 20.00–23.59
        </div>
        <img className="n-private__brutal" src="/private-event/brutal-logo.png" alt="" />
        <div className="n-private__location">
          Sergelgatan 8 [The pit&#125; // Live: 21.00
        </div>
        <div className="n-private__button">
          <button onClick={togglePopup}>Get your ticket</button>
        </div>
        <div className="n-private__text">
          ELI does not just sing; she declares. Her music, a testament to the power of voice and vision, invites us into a realm where the only expectation is the unexpected, transforming the act of listening into an act of radical self-discovery. Loud, opinionated, and divine, Eli’s songs are a manifesto of her own beliefs.
        </div>
        <svg className="n-private__logo" width="200" viewBox="0 0 200 37" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M190.607 1.44678H200V34.9717H190.848V17.005L180.347 34.9717H171.003L160.502 17.005V34.9717H151.398V1.44678H160.743L175.675 26.9758L190.607 1.44678Z" fill="white" />
          <path d="M119.973 0C133.075 0 143.72 8.14038 143.72 18.1593C143.72 28.2264 133.075 36.3186 119.973 36.3186C106.871 36.3186 96.2744 28.2264 96.2744 18.1593C96.2744 8.14038 106.871 0 119.973 0ZM119.973 29.1416C127.873 29.1416 134.327 24.2285 134.327 18.1593C134.327 12.1383 127.873 7.22519 119.973 7.22519C112.074 7.22519 105.667 12.1383 105.667 18.1593C105.667 24.2285 112.074 29.1416 119.973 29.1416Z" fill="white" />
          <path d="M75.0632 1.396L94.7157 34.9209H85.323L81.4214 28.2737H59.3604L55.4588 34.9209H46.1143L65.7186 1.396H75.0632ZM63.3584 21.4339H77.4234L70.3909 9.39187L63.3584 21.4339Z" fill="white" />
          <path d="M29.7196 1.44531H38.8715V34.9702H29.4788L9.10375 13.1501V34.9702H0V1.44531H9.58542L29.7196 23.0246V1.44531Z" fill="white" />
        </svg>

        <div onClick={closePopup} className="n-private__popup">
          <div className="n-private__popup-inner">
            <div className="n-main-container">
              {children}
            </div>
            <svg onClick={togglePopup} className="n-private__popup__close" fill="currentColor" width="16" viewBox="0 0 32 32" aria-hidden="true">
              <path d="M24 9.4L22.6 8 16 14.6 9.4 8 8 9.4 14.6 16 8 22.6 9.4 24 16 17.4 22.6 24 24 22.6 17.4 16 24 9.4z"></path>
            </svg>
          </div>
        </div>
      </div>

    </div>
  )
}