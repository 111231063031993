import { NavLink, useParams } from "react-router-dom"

export default function Failed() {
  const { orderId, orderKey } = useParams()
  
  return (
    <div className="tickets n-mt-6">
      <div className="n-leading-4 n-my-6">Payment error</div>

      <NavLink to={`/payment/${orderId}/${orderKey}`}
        className="btn btn--primary n-w-full n-mt-6">Go back and try again</NavLink>
    </div>
  )
}