import DefaultTemplate from 'views/templates/DefaultTemplate'
import SpecialTemplate from 'views/templates/SpecialTemplate'

import List from 'views/List'
import Item from 'views/Item'
import Payment from 'views/Payment'
import PaymentSuccess from 'views/PaymentSuccess'
import PaymentError from 'views/PaymentError'
import PaymentFailed from 'views/PaymentFailed'
import HandleResult from 'views/HandleResult'
import Ticket from 'views/Ticket'
import Terms from 'views/Terms'
import Privacy from 'views/Privacy'
import Login from 'views/check/Login'
import Check from 'views/check/Check'

import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

function GTM({ children }: any) {
  const location = useLocation()

  useEffect(() => {
    if (window.dataLayer && process.env.REACT_APP_GTM_KEY)
      window.dataLayer.push({
        event: "page_view",
        pagePath: window.location.href,
      })
  }, [location])

  return children
}

const routes = [
  {
    path: "/",
    element: <DefaultTemplate><GTM><List /></GTM></DefaultTemplate>
  },
  {
    path: "/:productSlug",
    element: <DefaultTemplate><GTM><Item /></GTM></DefaultTemplate>
  },
  {
    path: "/payment/:orderId/:orderKey",
    element: <DefaultTemplate><GTM><Payment /></GTM></DefaultTemplate>
  },
  {
    path: "/success/:orderId/:orderKey",
    element: <DefaultTemplate><GTM><PaymentSuccess /></GTM></DefaultTemplate>
  },
  {
    path: "/error/:orderId/:orderKey",
    element: <DefaultTemplate><GTM><PaymentError /></GTM></DefaultTemplate>
  },
  {
    path: "/failed/:orderId/:orderKey",
    element: <DefaultTemplate><GTM><PaymentFailed /></GTM></DefaultTemplate>
  },
  {
    path: "/ticket/:ticketCode",
    element: <DefaultTemplate><GTM><Ticket /></GTM></DefaultTemplate>
  },
  {
    path: "/paymentResult",
    element: <GTM><HandleResult /></GTM>
  },
  {
    path: "/terms-and-conditions",
    element: <GTM><Terms /></GTM>
  },
  {
    path: "/privacy-policy",
    element: <GTM><Privacy /></GTM>
  },

  // SPECIAL EVENTS
  {
    path: "/:productSlug/:productKey",
    element: <SpecialTemplate><GTM><Item /></GTM></SpecialTemplate>
  },
  {
    path: "/:productSlug/payment/:orderId/:orderKey",
    element: <SpecialTemplate><GTM><Payment /></GTM></SpecialTemplate>
  },
  {
    path: "/:productSlug/success/:orderId/:orderKey",
    element: <SpecialTemplate><GTM><PaymentSuccess /></GTM></SpecialTemplate>
  },

  // STAFF PAGES
  {
    path: "/login",
    element: <DefaultTemplate><GTM><Login /></GTM></DefaultTemplate>
  },
  {
    path: "/check",
    element: <DefaultTemplate><GTM><Check /></GTM></DefaultTemplate>
  }
]

export default routes