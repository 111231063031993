import './DefaultTemplate.scss'

import { useEffect } from 'react'

export default function DefaultTemplate({ children }: any) {
  const asPopup = window.location !== window.parent.location

  useEffect(() => {
    window.parent.postMessage('set-transparent-background', '*')
  }, [])

  function closePopup() {
    window.parent.postMessage('n-ticket-app-close', '*')
  }

  return asPopup ? (
    <div className="n-popup">
      <div className="n-popup-inner">
        <div className="n-main-container">
          {children}
        </div>
        <svg onClick={closePopup} className="n-popup__close" fill="currentColor" width="16" viewBox="0 0 32 32" aria-hidden="true">
          <path d="M24 9.4L22.6 8 16 14.6 9.4 8 8 9.4 14.6 16 8 22.6 9.4 24 16 17.4 22.6 24 24 22.6 17.4 16 24 9.4z"></path>
        </svg>
      </div>
    </div>
  ) : (
    <div className="n-default">
      <div className="n-main-container">
        {children}
      </div>
    </div>
  )
}