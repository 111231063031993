import ClickAwayListener from 'react-click-away-listener'

import { useState } from "react"
import Timeslot from './Timeslot'

interface Props {
  value: TTimeslot,
  timeslots: TTimeslot[]
  onChange?: Function
}

export default function TimeslotPicker({ value, timeslots, onChange }: Props) {
  const [opened, setOpened] = useState(false)

  return (
    <ClickAwayListener onClickAway={() => setOpened(false)}>
      <div className="n-relative">
        <div className={`n-relative n-cursor-pointer input ${opened ? 'focus' : ''}`}
          onClick={() => setOpened(!opened)}>
          <span>
            {value?.timeslotStart?.substring(0,5)} – {value?.timeslotEnd?.substring(0,5)}
          </span>
          <svg className={`n-absolute n-right-3 n-top-1/2 -n-translate-y-1/2 ${opened ? 'n-rotate-180' : ''}` } width="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 11L3 5.99999L3.7 5.29999L8 9.59999L12.3 5.29999L13 5.99999L8 11Z" fill="currentColor"/></svg>
        </div>
        {opened && <ul className="tp n-absolute n-right-0 n-mt-2 n-z-1">
          {timeslots.length ? timeslots.map((t, i) => (
            <Timeslot key={i}
              item={t}
              selected={t.id === value.id}
              onSelect={onChange} />
          )) : (
            <li className="tp-no-items">
              No available time on this day
            </li>
          )}
        </ul>
        }
      </div>
    </ClickAwayListener>
  )
}