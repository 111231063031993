import './Terms.scss'

export default function Terms() {
  document.querySelector('html')!.style.background = 'black'

  return (
    <div className="n-terms">
      <svg width="132" viewBox="0 0 132 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M124.86 0.956543H131.013V23.1104H125.018V11.2377L118.139 23.1104H112.018L105.139 11.2377V23.1104H99.1758V0.956543H105.297L115.079 17.8266L124.86 0.956543Z"
          fill="white" />
        <path
          d="M78.5887 0C87.1712 0 94.1444 5.37931 94.1444 12C94.1444 18.6525 87.1712 24 78.5887 24C70.0062 24 63.0645 18.6525 63.0645 12C63.0645 5.37931 70.0062 0 78.5887 0ZM78.5887 19.2573C83.7634 19.2573 87.9916 16.0106 87.9916 12C87.9916 8.02122 83.7634 4.77454 78.5887 4.77454C73.4139 4.77454 69.2173 8.02122 69.2173 12C69.2173 16.0106 73.4139 19.2573 78.5887 19.2573Z"
          fill="white" />
        <path
          d="M49.1686 0.922852L62.0423 23.0767H55.8894L53.3336 18.6841H38.8822L36.3264 23.0767H30.2051L43.0473 0.922852H49.1686ZM41.5012 14.1642H50.7147L46.1079 6.20667L41.5012 14.1642Z"
          fill="white" />
        <path
          d="M19.4684 0.955078H25.4635V23.1089H19.3106L5.96357 8.68983V23.1089H0V0.955078H6.27911L19.4684 15.215V0.955078Z"
          fill="white" />
      </svg>

      <div className="n-terms-content">
        <p><span>WELCOME TO NAOM!</span></p>

        <p>Before you begin your NAOM experience, here's a quick rundown of the need-to-know info:</p>

        <p><span>Time Slot Entry</span></p>

        <p>Tickets are valid for a specific time. To keep things running smoothly, entry is only allowed 15 minutes before
          and 15 minutes after your designated time slot.</p>

        <p><span>Rescheduling</span></p>

        <p>Life throws curveballs! If you need to reschedule your visit, you can do so up to 2 hours before your original
          time by contacting NAOM directly at <a href="mainto:hello@naom.co">hello@naom.co</a>. Rescheduling depends on
          availability.</p>

        <p><span>Final Sale</span></p>

        <p>Think of NAOM tickets like a one-way ticket to a fascinating world - once booked, they're locked in. No
          refunds,
          but that means more mind-bending experiences for you!</p>

        <p><span>Your Data is Safe with Us</span></p>

        <p>At NAOM, we take your privacy seriously. We are committed to complying with all General Data Protection
          Regulation
          (GDPR) requirements. We only collect the information necessary to process your booking and ensure a smooth
          experience at NAOM. Your data is securely stored and will never be shared with third parties without your
          consent. For more information on our data protection practices, please refer to our <a href="/privacy-policy" target="_blacnk">privacy policy</a>.</p>

        <p><span>That's it! Get ready for your NAOM experience!</span></p>

        <p>For any questions, feel free to contact us at <a href="mailto:hello@naom.co">hello@naom.co</a>.</p>
      </div>
    </div>
  )
}