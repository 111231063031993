interface Props {
  active: string
}

export default function Tabs({ active }: Props) {
  const steps = ['Tickets', 'Guests', 'Payment', 'Confirmation']

  return (
    <div className="steps n-flex">
      {steps.map((name) => (
        <div key={name} 
          className={`steps-item ${name === active ? 'active' : ''}`}>
          {name}
        </div>
      ))}
    </div>
  )
}