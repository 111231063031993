import { Scanner } from '@yudiel/react-qr-scanner'

import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import CHECK_IN from 'graphql/auth-check.gql'

export default function Check() {
  const navigate = useNavigate()

  const [showScanner, setShowScanner] = useState(true)
  const token = localStorage.getItem(process.env.REACT_APP_TOKEN as string) || ''

  const [CheckIn] = useMutation(CHECK_IN, { context: { headers: { Authorization: `Bearer ${token}` } } })
  
  const [ticket, setTicket] = useState<any>({})
  const guestsText = ticket?.guestQuantity + (ticket?.guestQuantity > 1 ? ' guests' : ' guest')

  const messages: any = {
    valid: 'TICKET IS VALID',
    insufficient_usages: 'TICKET IS USED',
    already_used: 'TICKET IS USED',
    wrong_time: 'NOT VALID',
    not_found: 'NOT VALID'
  }
  const [msg, setMsg] = useState('')
  const [color, setColor] = useState('') // n-text-green-600 n-text-red-600

  useEffect(() => {
    checkToken()
  })

  function checkToken() {
    const [, payload] = token.split('.')

    if (!token || Date.now() >= JSON.parse(atob(payload)).exp * 1000) {
      localStorage.removeItem(process.env.REACT_APP_TOKEN as string)
      navigate('/login')
    }
  }

  function getMessage() {
    if (msg === 'valid') { 
      return <>GUEST <strong>{ticket.usages}</strong> OF <strong>{ticket.guestQuantity}</strong> CHECKED IN</>
    } else if (msg === 'already_used' || msg === 'insufficient_usages') {
      return <><strong>{ticket.usages}</strong> OF <strong>{ticket.usages}</strong> GUESTS ALREADY CHECKED IN</>
    } else if (msg === 'wrong_time') {
      return 'TICKET IS NOT VALID FOR THIS EVENT'
    } else {
      return 'THE TICKET COUND NOT BE FOUND'
    }
  }

  function handleScan(result: any) {
    checkToken()

    if (result?.[0]?.rawValue) {
      setShowScanner(false)

      CheckIn({ variables: { code: result[0].rawValue.split('/').pop() } })
        .then(({ data }) => {
          if (data.checkinTicket.successful) {
            setMsg('valid')
            setColor('n-text-green-600')
          } else if (data.checkinTicket.error === 'unknown_failure') {
            alert('Something went wrong, please reload the page and try again')
            return
          } else {
            setMsg(data.checkinTicket.error)
            setColor('n-text-red-600')
          }

          setTicket(data.checkinTicket.ticket)
        })
        .catch((error) => {
          alert(error)
        })
    }
  }

  function handleReset() {
    setTicket({})
    setMsg('')
    setShowScanner(true)
  }

  return (
    <div className="">
      {showScanner ? (
        <div className="-n-mx-6 -n-mt-6">
          <Scanner onScan={handleScan} />
          <div className="n-text-sm n-leading-4 n-mt-5 n-ml-6">
            Point the camera at the <br/>
            QR code on the ticket.
          </div>
        </div>
      ) : (
        <>
          <div className="n-flex n-items-start n-justify-between">
            <div className="n-leading-4">
              {ticket?.code && (
                <>
                  <h2>{ticket.product.name}</h2>
                  <div className="n-whitespace-pre-line">
                    {ticket.visitTime ? (
                      `${ticket.visitTime.visitDate} ${ticket.visitTime.visitStart.replace(':00', '')} – ${ticket.visitTime.visitEnd.replace(':00', '')}`
                    ) : (ticket.product.description) }
                    <br/>
                    {guestsText}
                  </div>
                </>
              )}
            </div>
            <div className="n-font-medium n-text-sm">NAOM</div>
          </div>
          <div className="n-mt-6 n-py-12 n-border-t n-border-b n-text-center">
            <div className={`text-34 n-font-medium n-leading-none ${color}`}>{messages[msg]}</div>
            <div className="n-text-sm n-mt-6">{getMessage()}</div>
          </div>
          <button className="btn btn--primary n-fixed n-left-6 n-right-6 n-bottom-6"
            onClick={handleReset}>
              Scan another ticket
          </button>
        </>
      )}
    </div>
  )
}