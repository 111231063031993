import '@adyen/adyen-web/dist/es/adyen.css'
import './Adyen.scss'
import AdyenCheckout from '@adyen/adyen-web'

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'app/hooks'
import { useMutation, useQuery } from '@apollo/client'
import PAYMENT_METHODS_GET from 'graphql/payment-methods.gql'
import PAY_ORDER_MUTATION from 'graphql/order-pay.gql'
import ORDER_DETAILS_MUTATION from 'graphql/order-details.gql'

interface Props {
  storageConsent: boolean
}

export default function Adyen({ storageConsent }: Props) {
  const locale = 'en_US'
  const store = useAppSelector((s) => s.main)

  const navigate = useNavigate()

  const { data: paymentsData } = useQuery(PAYMENT_METHODS_GET)
  const [PayOrder] = useMutation(PAY_ORDER_MUTATION)
  const [AddOrderDetails] = useMutation(ORDER_DETAILS_MUTATION)

  const [checkout, setCheckout] = useState<any>(false)

  const configuration: any = {
    clientKey: process.env.REACT_APP_ADYEN_CLIENT_KEY,
    locale: locale,
    environment: process.env.REACT_APP_ADYEN_ENV,
    showPayButton: true,
    paymentMethodsConfiguration: {
      card: {
        amount: {
          value: store.order.total,
          currency: process.env.REACT_APP_CURRENCY,
        },
      },
    },
    onSubmit(state: any , component: any) {
      if (state.isValid) {
        onSubmit(state, component);
      }
    },
    onAdditionalDetails(state: any, component: any) {
      onAdditionalDetails(state, component);
    },
  }

  async function onSubmit(state: any, component: any) {
    const input = {
      provider: "ADYEN",
      countryCode: "SE", // TODO: ideally, we get from user, or we can hardcode it to Sweden for now.
      locale: locale,
      origin: process.env.REACT_APP_URL,
      returnUrl: `${process.env.REACT_APP_URL}/paymentResult?orderId=${store.order.id}&orderKey=${store.order.secretKey}`,
      payload: JSON.stringify(state.data),
      storageConsent
    }

    PayOrder({ variables: {
      id: store.order.id,
      secretKey: store.order.secretKey,
      input,
    }})
      .then(({ data }) => {
        const res = JSON.parse(data?.payOrder?.result?.payload)
        handleServerResponse(res, component)
      })
      .catch((error) => {
        console.error(error)
        alert("Error occurred. Look at console for details")
      })
  }

  async function onAdditionalDetails(state: any, component: any) {
    const input = {
      provider: "ADYEN",
      payload: JSON.stringify(state.data),
    }

    AddOrderDetails({ variables: { input } })
      .then(({ data }) => {
        const res = JSON.parse(data?.submitPaymentDetails?.result?.payload)
        handleServerResponse(res, component)  
      })
      .catch((error) => {
        console.error(error)
        alert("Error occurred. Look at console for details")  
      })
  }

  // Handles responses sent from your server to the client
  function handleServerResponse(res: any, component: any) {
    if (res.action) {
      component.handleAction(res.action)
    } else {
      switch (res.resultCode) {
        case "Authorised":
        case "Pending":
        case "Received":
          navigate(`/success/${store.order.id}/${store.order.secretKey}`)
          break
        case "Refused":
          navigate(`/failed/${store.order.id}/${store.order.secretKey}`)
          break
        default:
          navigate(`/error/${store.order.id}/${store.order.secretKey}`)
          break
      }
    }
  }

  async function initCheckout() {
    const checkout = await AdyenCheckout(configuration)
    checkout.create('dropin').mount('#n-adyen-container')
    setCheckout(checkout)
  }

  if (!checkout && paymentsData?.paymentMethods) {
    configuration.paymentMethodsResponse = JSON.parse(paymentsData.paymentMethods.result.payload)
    initCheckout()
  }
  
  return (
    <div id="n-adyen-container"></div>
  )
}