import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "app/hooks"
import { setOrderData } from "app/store-slice"
import { useMutation } from "@apollo/client"
import ORDER_DETAILS_MUTATION from 'graphql/order-details.gql'

export default function HandlePaymentResult() {
  const dispatch = useAppDispatch()

  const { orderId, orderKey, redirectResult, payload } = Object.fromEntries(  
    new URLSearchParams(window.location.search)
  )
  const navigate = useNavigate()

  const [AddOrderDetails] = useMutation(ORDER_DETAILS_MUTATION)

  const details: any = {}
  if (redirectResult) {
    details.redirectResult = redirectResult
  } else if (payload) {
    details.payload = payload
  }

  const input = {
    provider: 'ADYEN',
    payload: JSON.stringify({ details })
  }

  AddOrderDetails({ variables: { input } })
    .then(({ data }) => {
      dispatch(setOrderData({ id: orderId, secretKey: orderKey }))

      const response = JSON.parse(data?.submitPaymentDetails?.result?.payload)

      switch (response.resultCode) {
        case "Authorised":
        case "Pending":
        case "Received":
          navigate(`/success/${orderId}/${orderKey}`)
          break
        case "Refused":
          navigate(`/failed/${orderId}/${orderKey}`)
          break
        default:
          navigate(`/error/${orderId}/${orderKey}`)
          break
      }
    })
    .catch((error) => {
      console.error(error)
      alert("Error occurred. Look at console for details")  
    })

  return <></>
}