import { NavLink } from "react-router-dom"

import { useQuery } from "@apollo/client"
import LIST_QUERY from 'graphql/products-get.gql'

export default function List() {
  const { data: listData } = useQuery(LIST_QUERY, { variables: {} })
  const tickets = listData?.products.nodes ?? []

  return (
    <div className="n-border-t n-border-accent-500">
      {tickets.map((ticket: any) => (
        <NavLink key={ticket.id} 
          to={`/${ticket.slug}`}
          className="n-flex n-items-center n-justify-between n-py-3 n-cursor-pointer n-border-b n-border-accent-200 last:n-border-accent-500">
          <div className="n-leading-4">
            <h2>{ticket.name}</h2>
            <div className="n-whitespace-pre-line">{ticket.description}</div>
          </div>
          <svg width="6" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.00005 5L1.00005 10L0.300049 9.3L4.60005 5L0.300048 0.7L1.00005 -3.0598e-08L6.00005 5Z" fill="#171717"/></svg>
        </NavLink>
      ))}
    </div>
  )
}