import './Terms.scss'

export default function Terms() {
  document.querySelector('html')!.style.background = 'black'

  return (
    <div className="n-terms">
      <svg width="132" viewBox="0 0 132 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M124.86 0.956543H131.013V23.1104H125.018V11.2377L118.139 23.1104H112.018L105.139 11.2377V23.1104H99.1758V0.956543H105.297L115.079 17.8266L124.86 0.956543Z"
          fill="white" />
        <path
          d="M78.5887 0C87.1712 0 94.1444 5.37931 94.1444 12C94.1444 18.6525 87.1712 24 78.5887 24C70.0062 24 63.0645 18.6525 63.0645 12C63.0645 5.37931 70.0062 0 78.5887 0ZM78.5887 19.2573C83.7634 19.2573 87.9916 16.0106 87.9916 12C87.9916 8.02122 83.7634 4.77454 78.5887 4.77454C73.4139 4.77454 69.2173 8.02122 69.2173 12C69.2173 16.0106 73.4139 19.2573 78.5887 19.2573Z"
          fill="white" />
        <path
          d="M49.1686 0.922852L62.0423 23.0767H55.8894L53.3336 18.6841H38.8822L36.3264 23.0767H30.2051L43.0473 0.922852H49.1686ZM41.5012 14.1642H50.7147L46.1079 6.20667L41.5012 14.1642Z"
          fill="white" />
        <path
          d="M19.4684 0.955078H25.4635V23.1089H19.3106L5.96357 8.68983V23.1089H0V0.955078H6.27911L19.4684 15.215V0.955078Z"
          fill="white" />
      </svg>

      <div className="n-terms-content">
        <p><span>PRIVACY POLICY</span></p>

        <p>This Privacy Policy explains how NAOM ("we," "us," or "our") collects, uses, and discloses your personal information when you use our website or services, including booking tickets for the NAOM experience.</p>

        <p><span>Information We Collect</span></p>

        <p>We collect the following information necessary to process your booking and ensure a smooth experience at NAOM:</p>

        <p>Booking Information: This may include your name, email address, and any other information relevant to your reservation, such as the number of tickets purchased and your designated time slot.</p>

        <p><span>How We Use Your Information</span></p>

        <p>We use your information for the following purposes:</p>

        <p>To process your booking: We use your information to confirm your reservation, send you important updates about your visit, and manage your ticket purchase.</p>

        <p>To ensure a smooth experience: We may use your information to personalize your visit and provide you with relevant information about NAOM.</p>

        <p><span>Data Security</span></p>

        <p>We take your privacy seriously and are committed to protecting your personal information. We implement appropriate technical and organizational measures to ensure the security of your data against unauthorized access, disclosure, alteration, or destruction.</p>

        <p><span>Sharing Your Information</span></p>

        <p>We are committed to transparency and want to be clear: we do not share your personal information with any third-party companies without your explicit consent. Your data remains secure with NAOM.</p>

        <p><span>Your Rights</span></p>

        <p>Under the General Data Protection Regulation (GDPR), you have certain rights regarding your personal information. These rights include:</p>

        <ul>
          <li>The right to access your personal information</li>
          <li>The right to rectify inaccurate personal information</li>
          <li>The right to erasure of your personal information</li>
          <li>The right to restrict the processing of your information</li>
          <li>The right to data portability</li>
        </ul>

        <p><span>Changes to this Privacy Policy</span></p>

        <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website.</p>

        <p><span>Contact Us</span></p>

        <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:hello@naom.co">hello@naom.co</a></p>
      </div>
    </div>
  )
}