import EliTemplate from "./EliTemplate"
import NaomTemplate from "./NaomTemplate"
import DefaultTemplate from "./DefaultTemplate"
import FsagTemplate from "./FsagTemplate"

import { useParams } from "react-router-dom"

export default function SpecialTemplate({ children }: any) {
  const { productSlug } = useParams()
  
  switch (productSlug) {
    case 'eli-brutal-x-raw':
      return <EliTemplate>{children}</EliTemplate>
    case 'the-naom-party':
    case 'i-feel-lucky':
      return <NaomTemplate texts={{
        text2: 'Come and celebrate the revolution in cultural spaces at the opening of THE MUSEUM by NAOM on June 19th.'
      }}>{children}</NaomTemplate>
    case 'hoss-the-naom-party':
      return <NaomTemplate texts={{
        text2: 'Hoss invites you to come and celebrate the revolution in cultural spaces at the opening of THE MUSEUM by NAOM on June 19th.', 
      }}>{children}</NaomTemplate>
    case 'FSAG-Member-preview':
      return <FsagTemplate>{children}</FsagTemplate>
    default:
      return <DefaultTemplate>{children}</DefaultTemplate>
  }
}