import DateTime from "./DateTime"

import { useState } from "react"
import { useAppSelector, useAppDispatch } from 'app/hooks'
import { setUnit, setOrderData, prepareGuests } from 'app/store-slice'
import { useNavigate, useParams } from "react-router-dom"
import { useMutation } from '@apollo/client'
import CREATE_MUTATION from 'graphql/order-create.gql'
import UPDATE_MUTATION from 'graphql/order-update.gql'

interface Props {
  nextStep: Function
}

export default function Product({ nextStep }: Props) {
  const store = useAppSelector((s) => s.main)
  const dispatch = useAppDispatch()

  // const { productKey } = useParams()
  const navigate = useNavigate()
  
  const [Create] = useMutation(CREATE_MUTATION)
  const [Update] = useMutation(UPDATE_MUTATION)

  const [error, setError] = useState('')
  const totalPrice = 
    store.product.unitGroup?.units.reduce((total: number, { id, percent }: TUnit) =>
      total += Math.round(store.product.defaultPrice.price * percent / 10000) * (store.units?.[id] ?? 0)
    , 0) ?? 0

  const limit = Math.min(store.product?.quantityLimitMax ?? 100, store.timeslot?.leftUsages ?? 100)
  const totalUnits = store.product.unitGroup?.units.reduce(
    (r: any, { id }: any) => (r + store.units[id])
  , 0)
  const totalPaidUnits = store.product.unitGroup?.units.reduce(
    (r: any, { id, percent }: any) => (r + (+percent ? store.units[id] : 0))
  , 0)

  if (store.product.quantityLimitMax === 0 && !error) {
    setError('There are no tickets left for this event')
  }

  function getBeautyPrice(percent: number) {
    const result = Math.round(store.product.defaultPrice.price * percent / 10000)
    return result ? `${result} kr` : 'Free'
  }

  function decrementUnit(id: number) {
    dispatch(setUnit({ id, value: store.units[id] - 1 }))
    setError('')
  }
  function incrementUnit(id: number) {
    dispatch(setUnit({ id, value: store.units[id] + 1 }))
    setError('')
  }
  function proceed() {
    if (store.product.scheduleId && !store.timeslot?.id) {
      setError('Select date and time')
      return
    }

    if (totalUnits > limit) {
      setError(`Only ${limit} ${limit > 1 ? 'tickets' : 'ticket'} left for this time`)
      return
    }

    if (totalPaidUnits < store.product.quantityLimitMin) {
      setError(`You need to select at least ${store.product.quantityLimitMin} adult, student or senior tickets`)
      return
    }

    const lineItems:TLineItem[] = []
    Object.keys(store.units).forEach((id) => {
      if (store.units[id] !== 0) {
        const lineItem:TLineItem = {
          unitId: +id, 
          productId: +store.product.id, 
          quantity: +store.units[id]
        }
        
        if (store.product.scheduleId) {
          lineItem.timeslotId = +store.timeslot.id
        }
        
        lineItems.push(lineItem)
      }
    })

    if (!lineItems.length) {
      setError('Select at least one ticket')
      return
    }

    // TODO: Handle errors
    if (!store.order.id) {
      Create({ variables: { input: { lineItems } } })
        .then(({ data }) => {
          if (data.createOrder.successful) {
            dispatch(setOrderData(data.createOrder.result))
            dispatch(prepareGuests())
            nextStep()
          } else {
            setError(data.createOrder.messages?.[0].message)
          }
        })
        .catch((error) => console.log(error))
    } else {
      Update({ variables: { 
        id: store.order.id,
        secretKey: store.order.secretKey,
        input: { lineItems } 
      } })
        .then(({ data }) => {
          if (data.updateOrder.successful) {
            dispatch(setOrderData(data.updateOrder.result))
            dispatch(prepareGuests())
            nextStep()
          } else {
            setError(data.updateOrder.messages?.[0].message)
          }
        })
        .catch((error) => console.log(error))
    }
  }
  function back() {
    if (!store.order.id 
      || window.confirm("Are you sure you want to go back? Current changes will be discarded.")) {
      navigate("/")
    }
  }

  return (
    <div className="tickets n-mt-6">
      {store.product.id ? (
        <>
          <div className="n-leading-4">
            <h2>{store.product.name}</h2>
            <div className="n-whitespace-pre-line">{store.product.description}</div>
          </div>

          {store.product.scheduleId && 
            <DateTime />
          }

          <table className="table tickets-table n-mt-7">
            <tbody>
              {store.product.unitGroup?.units.map(({ id, name, percent }: TUnit) => (
                <tr key={id}>
                  <td>{name}</td>
                  <td className="n-w-24">{getBeautyPrice(percent)}</td>
                  <td className="n-py-7 n-w-16">
                    <div className="tickets-buttons">
                      <button disabled={store.units[id] === 0}
                        onClick={() => decrementUnit(id)}>
                        <svg width="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 2C11.3 2 14 4.7 14 8C14 11.3 11.3 14 8 14C4.7 14 2 11.3 2 8C2 4.7 4.7 2 8 2ZM8 1C4.15 1 1 4.15 1 8C1 11.85 4.15 15 8 15C11.85 15 15 11.85 15 8C15 4.15 11.85 1 8 1Z" fill="currentColor"/><path d="M12 7.5H4V8.5H12V7.5Z" fill="currentColor"/></svg>
                      </button>
                      <div>{store.units[id]}</div>
                      <button disabled={totalUnits >= limit} 
                        onClick={() => incrementUnit(id)}>
                        <svg width="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 2C11.3 2 14 4.7 14 8C14 11.3 11.3 14 8 14C4.7 14 2 11.3 2 8C2 4.7 4.7 2 8 2ZM8 1C4.15 1 1 4.15 1 8C1 11.85 4.15 15 8 15C11.85 15 15 11.85 15 8C15 4.15 11.85 1 8 1Z" fill="currentColor"/><path d="M12 7.5H8.5V4H7.5V7.5H4V8.5H7.5V12H8.5V8.5H12V7.5Z" fill="currentColor"/></svg>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}

              {error.length ? (
                <tr>
                  <td colSpan={3} className="n-text-red-500 !n-whitespace-normal">{error}</td>
                </tr>
              ) : (
                <tr>
                  <td>Total price incl. VAT</td>
                  <td>{totalPrice} kr</td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>

          <div className="n-flex n-items-center n-justify-between n-gap-6 n-mt-6">
            <div className="n-w-1/2">
              {/* {!productKey && 
                <button className="btn btn--link"
                  onClick={back}>
                  <svg width="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 8L10 3L10.7 3.7L6.4 8L10.7 12.3L10 13L5 8Z" fill="currentColor"/></svg>
                  <span>Back</span>
                </button>
              } */}
            </div>

            <button className="btn btn--primary n-w-1/2"
              disabled={!totalUnits}
              onClick={proceed}>
              Proceed to guests
            </button>
          </div>
        </>
      ) : (store.product.id === null ? (
        <div className="">Ticket unavailable or not found.</div>
      ) : (
        <div className="">Loading...</div>
      ))}
    </div>
  )
}