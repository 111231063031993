import { createSlice } from '@reduxjs/toolkit'

interface MainState {
  order: any,
  units: any,
  guests: any,
  step: string,
  product: any,
  timeslot: any
}

const dummyGuest = {
  firstName: '',
  lastName: '',
  email: '',
  code: '+46',
  phone: '',
  unitId: 0,
  productId: 0,
}

const initialState: MainState = {
  order: {},
  units: {},
  guests: [dummyGuest],
  step: 'Tickets',
  product: {},
  timeslot: {}
}

const settingsSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    prepareGuests(state) {
      state.guests = state.order.lineItems.reduce((result: any, item: any) => {
        const add = Array(item.quantity)
          .fill({ 
            ...dummyGuest,
            unitId: item.unit.id,
            unitName: item.unit.name,
            productId: item.product.id
          })
        
        result.push(...add)
        return result
      }, [])
    },
    setGuestData(state, { payload: { index, key, value } }) {
      Object.assign(state.guests[index], { [key]: value })
    },
    setOrderData(state, { payload }) {
      state.order = payload
    },
    setProductData(state, { payload }) {
      if (state.product.id !== payload.id) {
        const units = Object.fromEntries(
          payload.unitGroup?.units.map(({ id }: TUnit, i: number) => 
            [id, i === 0 ? payload.quantityLimitMin : 0]
          ) ?? []
        )
        Object.assign(state, { ...initialState, units }) // Reset all data if different ticket choosen
      }

      state.product = payload
    },
    setStep(state, { payload }) {
      state.step = payload
    },
    setTimeslotData(state, { payload }) {
      state.timeslot = payload
    },
    setUnit(state, { payload: { id, value } }) {
      state.units[id] = value
    },
    resetData(state) {
      state.order = {}
      state.timeslot = {}
      state.step = 'Tickets'
      state.product.unitGroup.units.forEach(({ id }: TUnit) => state.units[id] = 0)
    }
  }
})

export const {
  prepareGuests,
  setGuestData,
  setOrderData,
  setProductData,
  setStep,
  setUnit,
  setTimeslotData,
  resetData
} = settingsSlice.actions

export default settingsSlice.reducer