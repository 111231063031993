import './index.scss'

import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import { store } from 'app/store'
import { Provider as ReduxProvider } from 'react-redux'
import { createBrowserRouter, createHashRouter, RouterProvider } from "react-router-dom"
import routes from 'routes'
import TagManager from 'react-gtm-module'

document.title = process.env.REACT_APP_TITLE || ''
document.querySelector('meta[name="keywords"]')
  ?.setAttribute('content', process.env.REACT_APP_KEYWORDS || '')
document.querySelector('meta[name="description"]')
  ?.setAttribute('content', process.env.REACT_APP_DESCRIPTION || '')


if (process.env.REACT_APP_GTM_KEY) {
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_KEY
  })
  
  window.addGtmOrderEvent = (order: any, event = false) => {
    if (!order?.id)
      return

    const products = order.lineItems.map(({ product }: any) => product)
  
    const data: any = {
      order: {
        id: order.id,
        total: order.total / 100,
        netTotal: order.netTotal / 100,
        currency: "SEK",
        couponCode: null,
        productIds: products.reduce((result: string[], { id }: any) => {
          if (!result.includes(id)) result.push(id)
          return result
        }, []).join(','),
        productNames: products.reduce((result: string[], { name }: any) => {
          if (!result.includes(name)) result.push(name)
          return result
      }, []).join(','),
        totalQuantity: order.totalQuantity,
        // orgId: order.orgId,
        state: order.state
      }
    }
    if (event)
      data.event = event
  
    window.dataLayer.push(data)
  }
} else {
  window.addGtmOrderEvent = () => {}
}

export const apolloClientMain = new ApolloClient({
  cache: new InMemoryCache({ addTypename: false }),
  uri: process.env.REACT_APP_API_URL,
  headers: { 'x-app': process.env.REACT_APP_APP_TOKEN as string },
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'cache-first',
      errorPolicy: 'all',
    },
  },
  connectToDevTools: true
})

const rootEl = document.querySelector('#naom-ticket-app') as HTMLElement
const routerOptions = {
  basename: rootEl.dataset.basename || '/'
}
const router = rootEl.dataset.hash
  ? createHashRouter(routes, routerOptions)
  : createBrowserRouter(routes, routerOptions)

ReactDOM
  .createRoot(rootEl)
  .render(
    <ApolloProvider client={apolloClientMain}>
      <ReduxProvider store={store}>
        <RouterProvider router={router} />
      </ReduxProvider>
    </ApolloProvider>
  )

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics wendpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

declare global {
  type TLineItem = {
    productId: number,
    quantity: number,
    unitId?: number,
    timeslotId?: number
  }

  type TGuest = {
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    code: string,
    unitId: number
  }

  type TUnit = {
    id: number,
    name: string,
    percent: number
  }

  type TProduct = {
    id: number,
    name: string,
    slug: string,
    description: string,
    defaultPrice: {
      price: number
    },
    unitGroup: {
      units: TUnit[]
    }
  }

  type TTimeslot = {
    id: number
    leftUsages: number
    limit: number
    scheduleId: number
    state: string
    timeslotDate: string
    timeslotEnd: string
    timeslotStart: string
    usages: number
  }

  interface Window { 
    dataLayer: any,
    addGtmOrderEvent: Function
  }
}