import DatePicker from "./DatePicker"
import TimeslotPicker from "./TimeslotPicker"

import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { setTimeslotData } from 'app/store-slice'
import { useQuery, useLazyQuery } from '@apollo/client'
import GET_TIMESLOT_DAYS from 'graphql/timeslot-days.gql'
import GET_TIMESLOTS from 'graphql/timeslots-get.gql'

export default function DateTime() {
  const store = useAppSelector((s) => s.main)
  const dispatch = useAppDispatch()
  
  const [date, setDate] = useState<Date>()

  const { data: daysData } = useQuery(GET_TIMESLOT_DAYS, {
    variables: { id: +store.product.scheduleId }
  })
  const [getTimeslots, { data: timeslotsData }] = useLazyQuery(GET_TIMESLOTS) 
  const days = daysData?.timeslotDays.nodes.map(
    ({ timeslotDate }: any) => new Date(timeslotDate)
  )
  const timeslots = timeslotsData?.timeslots.nodes ?? []

  function handleDateChange(e: Date) {
    setDate(e)
    getTimeslots({
      variables: {
        id: +store.product.scheduleId,
        min: e.toLocaleDateString('sv-SV'),
        max: e.toLocaleDateString('sv-SV')
      }
    })
  }
  function handleTimeChange(e: TTimeslot) {
    dispatch(setTimeslotData(e))
  }

  useEffect(() => {
    if (daysData?.timeslotDays.nodes.length) {
      handleDateChange(new Date(daysData.timeslotDays.nodes[0]?.timeslotDate))
    }
  }, [daysData])

  useEffect(() => {
    if (timeslotsData?.timeslots.nodes[0]) {
      handleTimeChange(timeslotsData.timeslots.nodes[0])
    }
  }, [timeslotsData])
  
  return (
    <div className="n-flex n-gap-6 n-mt-6">
      <div className="n-w-1/2">
        {date ? (
          <DatePicker value={date}
            available={days}
            onChange={handleDateChange} />
        ) : (<div className="n-h-10 n-flex n-items-center">No available dates</div>)}
      </div>
      <div className="n-w-1/2">
        <TimeslotPicker value={store.timeslot} 
          timeslots={timeslots}
          onChange={handleTimeChange} />
      </div>
    </div>
  )
}